$(document).ready(function () {

    // Открытие/закрытие дропдауна с выбором городов
    $('.town-selection__js--dropdown').on('click', function (event) {
        event.preventDefault();

        let $townDropdown      = $('.town-selection__dropdown'),
            $townSelectionIcon = $('.town-selection__icon');

        $townDropdown.toggleClass('town-selection__dropdown_state--open');
        $townSelectionIcon.toggleClass('town-selection__icon_state--open');

        if ($townDropdown.hasClass('town-selection__dropdown_state--open')) {
            $townDropdown.slideDown('fast');
        } else {
            $townDropdown.slideUp('fast');
        }
    });

    // Открытие/закрытие дропдауна с выбором студии
    $('.navbar__link_js--dropdown').on('click', function (event) {
        event.preventDefault();

        let $dropdownContainer = $(this).parent('.navbar__item_dropdown'),
            $navbarDropdown    = $('.navbar__dropdown', $dropdownContainer);

        $('.navbar__dropdown_state--open').slideUp();
        $('.navbar__dropdown').removeClass('navbar__dropdown_state--open');
        $navbarDropdown.toggleClass('navbar__dropdown_state--open');

        if ($navbarDropdown.hasClass('navbar__dropdown_state--open')) {
            $navbarDropdown.slideDown('fast');
        } else {
            $navbarDropdown.slideUp('fast');
        }
    });

    $('.js_callback-btn').on('click', function () {
        $('.callback-modal').fadeIn();
    });
    $('.js_callback-overlay').on('click', function (event) {
        let $target = $(event.target);

        if ($target.hasClass('modal__overlay')) {
            $('.callback-modal').fadeOut();
        }
    });
    $('.js_callback-close').on('click', function () {
        $('.callback-modal').fadeOut();
    });

    $('.js_moving-link').on('click', function () {
        $('.moving-modal').fadeIn();
    });
    $('.js_moving-overlay').on('click', function (event) {
        let $target = $(event.target);

        if ($target.hasClass('modal__overlay')) {
            $('.moving-modal').fadeOut();
        }
    });
    $('.js_moving-close').on('click', function () {
        $('.moving-modal').fadeOut();
    });

    $('.js_policy-link').on('click', function (event) {
        event.preventDefault();

        $('.policy-modal').fadeIn();
    });
    $('.js_policy-overlay').on('click', function (event) {
        let $target = $(event.target);

        if ($target.hasClass('modal__overlay')) {
            $('.policy-modal').fadeOut();
        }
    });
    $('.js_policy-close').on('click', function () {
        $('.policy-modal').fadeOut();
    });

    $('.js_result-overlay').on('click', function (event) {
        let $target = $(event.target);

        if ($target.hasClass('modal__overlay')) {
            $('.result-modal').fadeOut();
        }
    });
    $('.js_result-close').on('click', function () {
        $('.result-modal').fadeOut();
    });

    $('.js_cookie-link').on('click', function (event) {
        event.preventDefault();

        $('.cookie-modal').fadeIn();
    });
    $('.js_cookie-overlay').on('click', function (event) {
        let $target = $(event.target);

        if ($target.hasClass('modal__overlay')) {
            $('.cookie-modal').fadeOut();
        }
    });
    $('.js_cookie-close').on('click', function () {
        $('.cookie-modal').fadeOut();
    });

    if ('closedCookieInfo' in localStorage) {
        const currentDate = new Date();
        const cookieInfo = new Date(localStorage.getItem('closedCookieInfo'));
        const oneDay = 1000 * 60 * 60 * 24;
        const differenceInDays = Math.floor((currentDate - cookieInfo) / oneDay);

        if (differenceInDays > 0) {
            $('.cookie-info').slideDown();
        }
    } else {
        $('.cookie-info').slideDown();
    }
    $('.js_cookie-info-close').on('click', function () {
        const currentDate = new Date();

        localStorage.setItem('closedCookieInfo', currentDate);
        $('.cookie-info').slideUp();
    });

    $('.js_main-burger-menu').on('click', function () {
        $(this).siblings('.main-header__container').addClass('main-header__container_state--open');
        $('body').addClass('menu-opened');
    });
    $('.js_main-menu-close').on('click', function () {
        $(this).parent().removeClass('main-header__container_state--open');
        $('body').removeClass('menu-opened');
    });

    let $page = $('html, body');
    $('.js_scroll-link').on('click', function() {
        $page.animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 400);
        return false;
    });
});

$(window).on('load', function () {
    let locationHref = document.location.href,
        url          = new URL(locationHref),
        onPageParam  = url.searchParams.get('onPage');

    if (onPageParam) {
        let $page = $('html, body'),
            $element = $('.' + onPageParam);

        if ($element.length > 0) {
            $page.animate({
                scrollTop: $($element).offset().top
            }, 1000);
        }
    }
});

$(document).on('click', function (event) {
    let $target = $(event.target);

    // Закрытие дропдауна с выбором городов
    if ($('.town-selection__dropdown').hasClass('town-selection__dropdown_state--open')) {
        if (!($target.hasClass('town-selection__js--dropdown') || $target.hasClass('town-selection__dropdown') || $target.hasClass('town-selection__items'))) {
            $('.town-selection__dropdown').slideUp('fast').removeClass('town-selection__dropdown_state--open');
        }
    }

    // Закрытие дропдауна с выбором студий
    if ($('.navbar__dropdown').hasClass('navbar__dropdown_state--open')) {
        if (!($target.hasClass('navbar__link_js--dropdown') || $target.hasClass('navbar__dropdown') || $target.hasClass('navbar__dropdown-items'))) {
            $('.navbar__dropdown').slideUp('fast').removeClass('navbar__dropdown_state--open');
        }
    }

    if ($target.is('.menu-opened')) {
        $('.main-header__container').removeClass('main-header__container_state--open');
        $('.header__content').removeClass('header__content_state--open');
        $('body').removeClass('menu-opened');
    }
});